import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { javascript } from '@codemirror/lang-javascript';
import { tags as t } from '@lezer/highlight';

export const myTheme = createTheme({
  theme: 'light',
  settings: {
    background: '#ffffff',
    backgroundImage: '',
    foreground: '#75baff',
    caret: '#5d00ff',
    selection: '#036dd626',
    selectionMatch: '#036dd626',
    lineHighlight: '#8a91991a',
    gutterBackground: '#fff',
    gutterForeground: '#8a919966',
  },
  //Blue THeme
//   styles: [
//     { tag: t.comment, color: '#787b8099' },
//     { tag: t.variableName, color: '#0080ff' },
//     { tag: [t.string, t.special(t.brace)], color: '#5c6166' },
//     { tag: t.number, color: '#5c6166' },
//     { tag: t.bool, color: '#5c6166' },
//     { tag: t.null, color: '#5c6166' },
//     { tag: t.keyword, color: '#5c6166' },
//     { tag: t.operator, color: '#5c6166' },
//     { tag: t.className, color: '#5c6166' },
//     { tag: t.definition(t.typeName), color: '#5c6166' },
//     { tag: t.typeName, color: '#5c6166' },
//     { tag: t.angleBracket, color: '#5c6166' },
//     { tag: t.tagName, color: '#5c6166' },
//     { tag: t.attributeName, color: '#5c6166' },
//   ],

styles: [
    { tag: t.comment, color: '#008000' },          // Comments
    { tag: t.variableName, color: '#001080' },     // Variable Names
    { tag: [t.string, t.special(t.brace)], color: '#A31515' },  // Strings and Braces
    { tag: t.number, color: '#098658' },           // Numbers
    { tag: t.bool, color: '#0000FF' },             // Booleans
    { tag: t.null, color: '#0000FF' },             // Null
    { tag: t.keyword, color: '#0000FF' },          // Keywords
    { tag: t.operator, color: '#000000' },         // Operators
    { tag: t.className, color: '#267F99' },        // Class Names
    { tag: t.definition(t.typeName), color: '#267F99' },  // Type Definitions
    { tag: t.typeName, color: '#267F99' },         // Type Names
    { tag: t.angleBracket, color: '#000000' },     // Angle Brackets
    { tag: t.tagName, color: '#800000' },          // Tag Names (HTML/JSX)
    { tag: t.attributeName, color: '#FF0000' },    // Attribute Names (HTML/JSX)
  ]
});

export const extensions = [javascript({ jsx: true })];