// import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components/NavBar";
import {HeroImage} from "./components/Banner"
import { Analyze } from "./components/Analyze";
import { About } from "./components/About";
import { Footer } from "./components/Footer";
import { Reports } from "./components/Reports";

function App() {
  return (
    <div className="App">
      <NavBar />
      <HeroImage/>
      <Analyze />
      <About />
      <Footer />
    </div>
  );
}

export default App;
