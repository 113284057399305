import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import vulnerabilitySvg from "../assets/img/vulneribility-svg.svg";
import suggestedFixesSvg from "../assets/img/suggested-fix-yellow.svg";
import bestPractice from "../assets/img/best-practice-green.svg";
import { ScoreMeter } from "../tools/ScoreMeter";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import {vscodeLight} from '@uiw/codemirror-theme-vscode';
import { myTheme , extensions} from "../tools/CodeMirrorTheme";
import copySVG from '../assets/img/copy_svg.svg'


//Var for Clipboard
// new ClipboardJS(".button");

export const Reports = ({
  securityScore,
  vulnerabilities,
  suggestedFixes,
  bestPractices,
  secureCode,
}) => {
  // Clean the data to remove any unwanted information
  const cleanText = (text) => {
    // Remove any mention of the overall security score in the text
    return text.replace(/Overall Security Score:.*$/, "").trim();
  };

  return (
    <section className="reports" id="reports">
      <div className="container">
        <div className="col-12">
          {/* Overall Code Security Results */}
          <div className="reports-bx wow zoomIn">
            <h2>Audit Results</h2>
            <br />
            <h3>Overall Code Security Score</h3>
            <div className="sub-sec">
              <p className="result-txt">
                A very good score is between 60 and 80. For best results, you
                should strive for 70 and above.
              </p>
              <div className="meter">
                <ScoreMeter score={securityScore} />
              </div>
            </div>
            <br />
            {/* Detailed Results Section */}
            <h3>Audit Result Details</h3>
            <div className="sub-sec">
              <ListGroup as="ol" numbered>
                <ListGroup.Item className="d-flex justify-content-between align-items-start list-item">
                  <img
                    src={vulnerabilitySvg}
                    alt="Vulnerability SVG"
                    className="report-svgs"
                  />
                  <div className="ms-2 me-auto">
                    <div
                      className="detail-subhead"
                      style={{ color: "#d94453" }}
                    >
                      Vulnerabilities
                    </div>
                    <p>
                      {cleanText(vulnerabilities) ||
                        "No vulnerabilities found."}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start list-item">
                  <img
                    src={suggestedFixesSvg}
                    alt="Suggested Fixes SVG"
                    className="report-svgs"
                  />
                  <div className="ms-2 me-auto">
                    <div
                      className="detail-subhead"
                      style={{ color: "#e7d323" }}
                    >
                      Suggested Fixes
                    </div>
                    <p>{cleanText(suggestedFixes) || "No suggested fixes."}</p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start list-item">
                  <img
                    src={bestPractice}
                    alt="Best Practice SVG"
                    className="report-svgs"
                  />
                  <div className="ms-2 me-auto">
                    <div
                      className="detail-subhead"
                      style={{ color: "#3c9b40" }}
                    >
                      Best Practices
                    </div>
                    <p>
                      {cleanText(bestPractices) || "No best practices found."}
                    </p>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <br/>
            <br/>
            {/* Corrected Code Section */}
            <h2>Secure Code</h2>
            <div className="code-section">
              <div className="top-bx">
              <button onClick={() =>  navigator.clipboard.writeText(secureCode)}><img src={copySVG} alt="Copy Svg" className="code-bx-svgs" /></button>
              </div>
              <CodeMirror 
                    theme={vscodeLight}
                    highlightActiveLine = {false}
                    lineNumbers = {false}
                    foldgutter={false}
                    editable = {false}
                    className="code-input-bx"
                    value={secureCode}
                    extensions={extensions}
                  />
              <div className="btm-line">
              Review and test the code before using it in production.
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
