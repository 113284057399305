import "react-multi-carousel/lib/styles.css";
import ListGroup from "react-bootstrap/ListGroup";
import svgBug from "../assets/img/bug-spider.svg";
import svgSecureCode from "../assets/img/secure-code.svg";
import svgCard from "../assets/img/card-search.svg";
import svgSecurity from "../assets/img/security2.svg";
import step1 from "../assets/img/Code.png"
import step2 from "../assets/img/Check.png"
import step3 from "../assets/img/Improve.png"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export const About = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="about" id="about">
      <div className="container">
        <div className="col-12">
        <h2 className="abt-heading" >How to use the free code checker?</h2>  
          {/* How to check - Section */}
          <div className="about-bx wow zoomIn">
            <br/>
            <p className="card-bx">
            <Card style={{ width: '20rem' }} className="abt-card">
              <Card.Img variant="top" src={step1} className="abt-png" />
              <Card.Body>
                <Card.Title className="card-title">Code</Card.Title>
                <Card.Text>
                  Copy & Paste your code into the editor.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: '20rem' }} className="abt-card">
              <Card.Img variant="top" src={step2} className="abt-png" />
              <Card.Body>
                <Card.Title className="card-title">Check</Card.Title>
                <Card.Text>
                  Click the <span style={{color:'linear-gradient(90deg, hsla(173, 89%, 70%, 1) 0%, hsla(215, 95%, 51%, 1) 0%, hsla(31, 77%, 85%, 1) 98%)',fontWeight: "bold"}}>Check Security</span> button.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: '20rem' }} className="abt-card">
              <Card.Img variant="top" src={step3} className="abt-png" />
              <Card.Body>
                <Card.Title className="card-title">Improve</Card.Title>
                <Card.Text>
                  Use the results to improve your code.
                </Card.Text>
              </Card.Body>
            </Card>
            </p>
          </div>


          <h2 className="abt-heading">How Sitefend Checks Code Security</h2>
          {/* List Section */}
          <div className="about-bx wow zoomIn">
            <div className="sub-sec">
              <ListGroup as="ol" numbered>
                <ListGroup.Item className="d-flex justify-content-between align-items-start list-item">
                  <img src={svgBug} alt="Bug Svg" className="abt-svgs" />
                  <div className="ms-2 me-auto">
                    <div className="abt-subhead">
                      Advanced Vulnerability Detection
                    </div>
                    <p className="">
                      Sitefend uses cutting-edge AI technology to identify
                      potential security vulnerabilities in your code, ensuring
                      that no flaw goes unnoticed.
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start list-item">
                  <img
                    src={svgSecureCode}
                    alt="Shield Warning Svg"
                    className="abt-svgs"
                  />
                  <div className="ms-2 me-auto">
                    <div className="abt-subhead">Secure Code</div>
                    <p className="">
                    SiteFend doesn't just identify problems—it also provides a secure version of your code, giving you a clear solution to fix the vulnerabilities and improve overall code quality.
                    </p>
                  </div>
                </ListGroup.Item>
              </ListGroup>
              <ListGroup as="ol" numbered>
                <ListGroup.Item className="d-flex justify-content-between align-items-start list-item">
                  <img
                    src={svgSecurity}
                    alt="Security Svg"
                    className="abt-svgs"
                  />
                  <div className="ms-2 me-auto">
                    <div className="abt-subhead">
                      Best Practices Recommendations
                    </div>
                    <p className="">
                      Sitefend provides actionable suggestions to improve your
                      code's security posture by recommending industry best
                      practices that are not currently implemented.
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start list-item">
                  <img src={svgCard} alt="Card Svg" className="abt-svgs" />
                  <div className="ms-2 me-auto">
                    <div className="abt-subhead">
                      Comprehensive Security Score
                    </div>
                    <p className="">
                      After analyzing your code, Sitefend generates an overall
                      security score, giving you a clear, concise measure of
                      your code's security health.
                    </p>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </div>
          <br />

          {/* Why Use Sitefend Section */}
          <div className="about-bx wow zoomIn">
            <h3>Why Use Sitefend?</h3>
            <p>
              Sitefend offers a fast, reliable, and easy-to-use solution for
              developers to ensure their code is secure. With Sitefend, you get
              peace of mind knowing that your application is protected against
              potential threats, allowing you to focus on building great
              features instead of worrying about security vulnerabilities.
            </p>
          </div>

          {/* About Sitefend - Section */}
          <div className="about-bx wow zoomIn">
            <h3>About Sitefend</h3>
            <p>
              Sitefend is dedicated to providing developers with an efficient,
              reliable tool to secure their code. Leveraging advanced AI
              technology, Sitefend ensures that your code is free from
              vulnerabilities, follows best practices, and meets the highest
              security standards. Protect your applications with Sitefend and
              build with confidence.
            </p>
          </div>

          {/* Is Sitefend Safe - Section */}
          <div className="about-bx wow zoomIn">
            <h3>Is SiteFend Safe?</h3>
            <p>
              Absolutely. Sitefend uses state-of-the-art security protocols to
              ensure that your code remains confidential and protected
              throughout the analysis process. Your data is never stored or
              shared, ensuring complete privacy and security. Trust Sitefend to
              keep your code safe while providing the insights you need to
              strengthen your application's security.
            </p>
          </div>

          {/* Gemini Competition - Section */}
          <div className="about-bx wow zoomIn">
            <h3>SiteFend Nominated for <span style={{color:'linear-gradient(90deg, hsla(173, 89%, 70%, 1) 0%, hsla(215, 95%, 51%, 1) 0%, hsla(31, 77%, 85%, 1) 98%)',fontWeight: "bold" }} >Google Gemini API Competition</span></h3>
            <p>
              SiteFend is proud to announce our nomination for the Google Gemini API Competition! Our AI-powered code security checker has been recognized for its innovation and impact. We need your support to win! Be sure to cast your vote <a href="https://ai.google.dev/competition/projects/sitefend " target="_blank"><span style={{color:"#1F4E79", fontWeight:"bold" }} > here</span></a>.
              <br/>
              Thank you for believing in SiteFend!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
