import React from 'react';
import backgroundImage from '../assets/img/blue_waves.png';
import '../Banner.css';

export const HeroImage =() => {
  return (
    <header style={{ paddingLeft: 0 }}>
    <div className="banner">
      <div className="banner-content">
        <p className='up-header'>Write Better Code</p>
        <h1>Code Checker</h1>
        <br/>
        <p>Check your code security before your next commit, and identify critical bugs using our free online code checker — powered by AI</p>
      </div>

      <svg viewBox="0 0 1440 310">
        <path fill="#0A6EAE" fillOpacity="1" d="M0,160L80,176C160,192,320,224,480,240C640,256,800,256,960,218.7C1120,181,1280,107,1360,69.3L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
        <path fill="#128AC3" fillOpacity="1" d="M0,224L80,224C160,224,320,224,480,213.3C640,203,800,181,960,170.7C1120,160,1280,160,1360,160L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
        <path fill="#2091CC" fillOpacity="1" d="M0,288L80,282.7C160,277,320,267,480,234.7C640,203,800,149,960,117.3C1120,85,1280,75,1360,80L1440,85L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
        <path fill="#36ABDE" fillOpacity="1" d="M0,224L80,218.7C160,213,320,203,480,202.7C640,203,800,213,960,229.3C1120,245,1280,267,1360,277.3L1440,288L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
        <path fill="#A5D1F5" fillOpacity="1" d="M0,288L80,282.7C160,277,320,267,480,256C640,245,800,235,960,213.3C1120,192,1280,160,1360,149.3L1440,139L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
        <path fill="#F5F5F5" d="M0,320L48,314.7C96,309,192,299,288,293.3C384,288,480,288,576,293.3C672,299,768,309,864,309.3C960,309,1056,299,1152,293.3C1248,288,1344,288,1392,288L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
       </svg>
    </div>
    </header>
  );
}